.banner {
  height: 30.4vw;
  background: url("/banner.jpg") no-repeat center center;
  background-size: cover; }

.list {
  padding: 6.4vw 2.13333vw; }

.list_item {
  background: #ffffff;
  box-shadow: 0 1.06667vw 3.2vw 0 rgba(0, 0, 0, 0.04);
  border-radius: 2.13333vw;
  padding: 2.13333vw;
  margin-bottom: 6.4vw;
  border: 1 solid #f1f1f1; }

.list_item_title {
  font-size: 4.8vw;
  color: #333;
  font-weight: bold;
  margin-bottom: 1.6vw; }

.list_item_desc {
  color: #666666; }

.list_item_head {
  padding: 2.13333vw 2.13333vw 4.26667vw;
  border-bottom: 1px dashed #f1f1f1;
  position: relative; }
  .list_item_head:after {
    display: block;
    content: "";
    width: 2.13333vw;
    height: 2.13333vw;
    border-width: 0.53333vw;
    border-style: solid;
    border-color: #999 #999 transparent transparent;
    transform: rotate(45deg) translate3d(0, -50%, 0);
    position: absolute;
    right: 4.26667vw;
    top: 50%; }

.tags {
  padding: 2.13333vw 2.13333vw 0;
  color: #999; }
