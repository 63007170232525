.list {
  background: #fff;
  padding: 2.66667vw 5.33333vw 5.33333vw;
  color: #142D58;
  font-size: 4vw; }
  .list li {
    border-bottom: 1px solid #DCE2EB;
    padding: 2.66667vw 0; }
  .list a,
  .list span {
    display: block;
    width: 100%;
    box-sizing: border-box; }
  .list span {
    display: flex;
    justify-content: space-between;
    align-items: center; }
