.list {
  padding: 6.4vw 4.26667vw; }

.list_item {
  margin-bottom: 10.66667vw; }
  .list_item h3 {
    font-size: 4.53333vw;
    color: #333;
    font-weight: bold; }

.more {
  padding: 4.26667vw 0 0;
  color: #119cfe;
  font-size: 3.73333vw; }
