.banner {
  height: 26.66667vw;
  background: url("/online.png") no-repeat center center;
  background-size: cover;
  font-size: 5.33333vw;
  color: #FFFFFF;
  box-sizing: border-box;
  padding: 5.33333vw;
  display: flex;
  align-items: center;
  font-weight: bold; }

.scroll_container {
  height: calc(100vh - 26.66667vw);
  position: relative;
  box-sizing: border-box;
  background: #fff; }
  .scroll_container:after {
    display: block;
    clear: both;
    visibility: hidden;
    content: ''; }

.scroll_menu {
  width: 26.66667vw;
  height: calc(100vh - 26.66667vw);
  float: left;
  white-space: nowrap; }

.menu_item {
  font-size: 3.73333vw;
  color: #666666;
  margin: 5.33333vw 0;
  text-align: center; }
  .menu_item span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    box-sizing: border-box;
    line-height: 1.5;
    padding: 1.06667vw 2.13333vw;
    border-radius: 10.66667vw; }
  .menu_item .active {
    background: #118EE9;
    color: #fff; }

.scroll_list {
  float: left;
  width: calc(100vw - 26.66667vw);
  height: calc(100vh - 26.66667vw);
  box-sizing: border-box;
  border-left: 1px solid rgba(100, 100, 100, 0.1); }

.list_item {
  padding: 2.66667vw 4vw;
  font-size: 4vw;
  color: #333333;
  margin: 0;
  box-shadow: 0 1px 0 rgba(100, 100, 100, 0.1);
  position: relative; }
  .list_item span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .list_item:after {
    display: block;
    content: '';
    width: 2.13333vw;
    height: 2.13333vw;
    border: 1px solid rgba(100, 100, 100, 0.4);
    border-top: none;
    border-left: none;
    transform: rotate(-45deg) translateY(-50%);
    position: absolute;
    right: 2.66667vw;
    top: 50%; }
