.detail_new {
  min-height: 100vh;
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: #ffffff; }
  .detail_new .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2.66667vw; }
    .detail_new .header .back {
      width: 5.33333vw;
      height: 5.33333vw;
      background: url("/help_new/icon_back1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 2.66667vw; }
    .detail_new .header .title {
      font-size: 4.8vw;
      color: #111E36;
      font-weight: 500; }
  .detail_new .content {
    padding: 4.26667vw;
    font-family: PingFangSC-Regular, PingFang SC; }
    .detail_new .content .content_tips {
      font-size: 3.73333vw;
      font-weight: 400;
      color: #121E36; }
    .detail_new .content .content_t {
      font-size: 4.26667vw;
      font-weight: 500;
      margin-top: 2.66667vw; }
    .detail_new .content p {
      font-size: 3.30667vw; }
  .detail_new .iKonw {
    width: 91.46667vw;
    height: 11.73333vw;
    background: #FF7401;
    border-radius: 0.8vw;
    margin: 5.33333vw auto;
    line-height: 11.73333vw;
    text-align: center;
    font-size: 4.8vw;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF; }
  .detail_new .iKonw_k {
    background: rgba(255, 116, 1, 0.6); }
