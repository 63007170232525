.detail_new {
  min-height: 100vh;
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC;
  background: #ffffff; }
  .detail_new .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2.66667vw; }
    .detail_new .header .back {
      width: 5.33333vw;
      height: 5.33333vw;
      background: url("/help_new/icon_back1.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 2.66667vw; }
    .detail_new .header .title {
      font-size: 4.8vw;
      color: #111E36;
      font-weight: 500; }
  .detail_new .content {
    padding: 4.26667vw; }
    .detail_new .content p {
      margin-bottom: 2.66667vw;
      font-size: 3.73333vw;
      color: #121E36; }
