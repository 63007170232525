body {
  background: #ffffff; }

.section {
  min-height: 100vh;
  position: relative; }

.banner {
  position: relative;
  font-family: PingFangSC-Medium, PingFang SC;
  height: 45.33333vw;
  background: url("/help_new/help_new_bg.png") no-repeat;
  background-size: 100% 100%;
  font-size: 7.73333vw;
  color: #FFFFFF;
  box-sizing: border-box;
  font-weight: 500;
  padding: 10.66667vw 5.33333vw; }
  .banner .back {
    width: 5.33333vw;
    height: 5.33333vw;
    background: url("/help_new/icon_back.png") no-repeat;
    background-size: 100% 100%; }
  .banner .guide {
    left: 8vw;
    bottom: 6.4vw;
    position: absolute; }

.scroll_container {
  position: relative;
  box-sizing: border-box;
  background: #fff;
  padding: 4vw; }
  .scroll_container .item {
    width: 100%;
    height: 19.46667vw;
    background: #FFFFFF;
    box-shadow: 0px 1.6vw 8vw 0px rgba(2, 24, 51, 0.12);
    border-radius: 3.2vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.66667vw; }
    .scroll_container .item .item_left {
      padding-left: 4vw; }
      .scroll_container .item .item_left .title {
        color: #121E36;
        font-size: 4.26667vw;
        font-weight: 500; }
      .scroll_container .item .item_left .tips {
        font-size: 3.2vw;
        color: #878C9C; }
    .scroll_container .item .item_icon {
      width: 9.6vw;
      height: 9.6vw;
      margin-right: 5.33333vw; }
    .scroll_container .item:nth-of-type(1) .item_icon {
      background: url("/help_new/icon_1.png") no-repeat;
      background-size: 100% 100%; }
    .scroll_container .item:nth-of-type(2) .item_icon {
      background: url("/help_new/icon_3.png") no-repeat;
      background-size: 100% 100%; }
    .scroll_container .item:nth-of-type(3) .item_icon {
      background: url("/help_new/icon_2.png") no-repeat;
      background-size: 100% 100%; }
  .scroll_container .question {
    margin-top: 8vw;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .scroll_container .question .title {
      margin-left: 4vw;
      font-size: 5.06667vw;
      font-weight: 500;
      color: #121E36; }
    .scroll_container .question .cut {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 6.93333vw;
      background: #EFF1F7;
      border-radius: 3.46667vw;
      width: 22.66667vw; }
      .scroll_container .question .cut .icon_refresh {
        width: 4.26667vw;
        height: 4.26667vw;
        background: url("/help_new/icon_refresh.png") no-repeat;
        background-size: 100%  100%;
        margin-top: 0.53333vw;
        margin-right: 0.8vw; }

.askList {
  margin-left: 8vw;
  padding-bottom: 26.66667vw; }
  .askList .askList_item {
    border-bottom: 1px solid #E6EBF5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 13.33333vw; }
    .askList .askList_item .askList_title {
      font-size: 4vw;
      color: #121E36; }
    .askList .askList_item .askList_arrow {
      width: 4vw;
      height: 4vw;
      background: url("/help_new/icon_arrow_right.png") no-repeat;
      background-size: 100%;
      margin-right: 4.26667vw; }

.footer {
  width: 91.46667vw;
  margin: 8vw  4.26667vw 4.26667vw;
  height: 11.73333vw;
  background: #FF7401;
  border-radius: 1.06667vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 4.8vw;
  position: fixed;
  bottom: 0; }
  .footer .icon_kefu {
    width: 6.93333vw;
    height: 5.86667vw;
    background: url("/help_new/icon_kefu.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 2.66667vw; }
