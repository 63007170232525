.header {
  background: #fff;
  padding: 1.6vw 2.66667vw;
  font-size: 4.8vw;
  font-weight: bold;
  color: #142D58;
  text-align: center;
  border-bottom: 1px solid #DCE2EB; }

.nav_fixed {
  width: 10.66667vw;
  flex-shrink: 0;
  flex-grow: 0;
  border: none;
  background: transparent;
  outline: none;
  appearance: none; }

.search {
  background: #F5F7FB;
  padding: 2.66667vw 5.33333vw; }

.search_btn {
  background: #fff;
  border-radius: 1.06667vw;
  appearance: none;
  font-size: 3.73333vw;
  color: #A6AEBD;
  display: flex;
  justify-content: center;
  align-items: center; }

.searchbar {
  font-size: 4vw;
  color: #142D58;
  padding: 5.33333vw; }
  .searchbar input {
    height: 10.66667vw;
    padding: 1.6vw 2.66667vw 1.6vw 10.66667vw;
    box-sizing: border-box;
    margin-right: 2.66667vw;
    background: #F5F7FB url("/icon/search.png") no-repeat left center;
    background-size: 10.66667vw; }

.search_list {
  height: calc(100vh - 21.33333vw);
  overflow-y: scroll;
  overflow-x: hidden; }

.category {
  background: #fff;
  padding: 4vw 5.33333vw;
  margin-bottom: 2.66667vw; }
  .category a {
    margin-right: 2.66667vw; }
    .category a:nth-child(3n) {
      margin-right: 0; }

.category_item {
  white-space: nowrap;
  width: 27.73333vw;
  height: 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFEAD9;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #594C42;
  font-size: 3.73333vw;
  font-weight: bold;
  border-radius: 1.6vw;
  margin: 1.33333vw 0; }

.title {
  font-size: 4.8vw;
  font-weight: bold;
  border-bottom: 1px solid #DCE2EB;
  padding: 2.66667vw 0;
  margin: 0 5.33333vw; }
