.rules {
  padding: 8vw 2.66667vw;
  background: #30363d;
  color: rgba(255, 255, 255, 0.6);
  font-size: 3.46667vw; }
  .rules h3 {
    font-size: 4.26667vw;
    font-weight: 500;
    margin-bottom: 2.66667vw; }
  .rules p {
    margin-bottom: 2.66667vw; }

.more {
  color: white; }
