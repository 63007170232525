.list {
  background: #F5F7FB;
  min-height: 100vh; }
  .list h3 {
    font-size: 4.8vw;
    color: #142D58;
    margin: 0 auto 2.66667vw;
    line-height: 1;
    font-weight: bold; }
  .list .summary {
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .list .summary span {
      display: inline-block;
      border-radius: 1.06667vw;
      border: 1px solid #f1f1f1;
      color: #aaa;
      padding: 1.6vw 5.33333vw;
      text-align: center;
      line-height: 1;
      background: #f1f1f1; }
      .list .summary span.active {
        color: #FC8D56;
        background: #fff; }
  .list a {
    display: block; }
  .list .thumb {
    position: relative;
    overflow: hidden; }
    .list .thumb span {
      position: absolute;
      right: -16vw;
      top: 0;
      z-index: 9;
      background: #959faf;
      color: #fff;
      padding: 2.13333vw 5.33333vw;
      min-width: 32vw;
      transform: rotate(45deg) translate(1.33333vw, 2.66667vw);
      font-weight: bold;
      text-align: center;
      font-size: 3.46667vw;
      line-height: 1; }
      .list .thumb span.active {
        color: #fff;
        background: #4D8CF5; }
  .list img {
    display: block;
    border-radius: 1.06667vw;
    margin: 0 auto 4vw;
    min-height: 21.33333vw;
    max-height: 42.66667vw;
    object-fit: cover;
    width: 100%; }
  .list .list_item {
    padding: 4vw;
    background: #fff; }
    .list .list_item:not(:last-child) {
      margin-bottom: 4vw; }
  .list .time {
    color: #B1BAC9;
    padding-left: 6.4vw;
    background: url("/icon/time.png") no-repeat left center;
    background-size: contain;
    line-height: 1.2; }

.loading {
  padding: 2vw;
  text-align: center;
  color: #999; }
