.title {
  background: #FAFAFA;
  padding: 4vw;
  font-size: 3.73333vw;
  color: #999999; }

.content li {
  padding: 0 4vw; }
  .content li a {
    font-size: 4.26667vw;
    color: #333333;
    display: block;
    padding: 4vw 0;
    font-weight: 600;
    box-shadow: 0 1px 0 rgba(100, 100, 100, 0.15);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.intro {
  padding: 4vw; }
  .intro > div {
    border-radius: 5.33333vw;
    background: #fff;
    margin-bottom: 4vw;
    box-shadow: 0 0 8vw rgba(100, 100, 100, 0.2);
    padding: 6.4vw 18.66667vw 6.4vw 5.33333vw;
    font-size: 3.73333vw;
    color: #999;
    background-size: 11.73333vw;
    background-repeat: no-repeat;
    background-position: right 5.33333vw center; }
  .intro h3 {
    font-size: 4.26667vw;
    font-weight: bold;
    color: #333; }
